.confirmation {
    display: flex;
    padding: 15px 0;
    gap: 15px;
    align-items: center;
}

.icon {
    svg {
        fill: var(--color-green);
    }
}

.content {
    color: var(--color-green);
    margin: 0;
    p {
        margin: 0;
    }
}