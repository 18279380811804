.question-heading {
    @mixin bodyLg;
}

.bold {
    font-weight: bold;
}

.answers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}