.ingredients-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  overflow: hidden;
  width: 120px;
  align-items: center;
  text-align: center;

  @mixin md {
    width: 150px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 110px;
    height: 110px;
    margin-bottom: 10px;

    @mixin md {
      width: 120px;
      height: 120px;
    }

    .img-front {
      z-index: 1;
      width: 95px;
      height: 95px;
      display: flex;
      align-items: center;

      @mixin md {
        width: 105px;
        height: 105px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .img-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 95px;
      height: 105px;

      @mixin md {
        width: 105px;
        height: 116px;
      }

      svg {
        height: 116px;
        width: auto;
      }
    }
  }

  .title {
    @mixin bodyMd;

    @mixin md {
      @mixin rudBody1Medium;
    }
  }

  .description {
    margin-top: 8px;
    @mixin rudBody1Regular;
    color: #918E8E;
  }
}
