/* fadeInUp */
:global(.fadeInUp-enter) {
  opacity: 0!important;
  transform: translate(0, 40%)!important;
  display: block!important;
}

:global(.fadeInUp-enter-active) {
  opacity: 1!important;
  transform: translate(0, 0)!important;
  transition: all 800ms;
  display: block!important;
  @mixin md {
    transition-delay: 50ms;
  }
}

:global(.fadeInUp-enter-done) {
  opacity: 1!important;
  display: block!important;
  transform: translate(0, 0)!important;
}

:global(.fadeInUp-exit) {
  opacity: 1!important;
  display: block!important;
  transform: translate(0, 0)!important;
}

:global(.fadeInUp-exit-active) {
  opacity: 0!important;
  transition: all 500ms;
  transform: translate(0, 0)!important;
}

:global(.fadeInUp-exit-done) {
  opacity: 0!important;
  transition: all 500ms;
  transform: translate(0, 0)!important;
  display: none!important;
}


/* fadeIn */
:global(.fadeIn-enter) {
  opacity: 0!important;
}

:global(.fadeIn-enter-active) {
  opacity: 1!important;
  transition: all 500ms;
}

:global(.fadeIn-enter-done) {
  opacity: 1!important;
}

:global(.fadeIn-exit) {
  opacity: 1!important;
}

:global(.fadeIn-exit-active) {
  opacity: 0!important;
  transition: all 500ms;
}


/* fadeInDown */
:global(.fadeInDown-enter) {
  opacity: 0!important;
  transform: translate(0, -100%)!important;
  display: block!important;
}

:global(.fadeInDown-enter-active) {
  opacity: 1!important;
  transform: translate(0, 0)!important;
  transition: all 1000ms;
  display: block!important;
}

:global(.fadeInDown-enter-done) {
  opacity: 1!important;
  transform: translate(0, 0)!important;
  display: block!important;
}

:global(.fadeInDown-exit) {
  opacity: 1!important;
  transform: translate(0, 0)!important;
  display: block!important;
}

:global(.fadeInDown-exit-active) {
  opacity: 0!important;
  transform: translate(0, 0)!important;
  transition: all 500ms;
  display: block!important;
}

:global(.fadeInDown-exit-done) {
  opacity: 0!important;
  transform: translate(0, 0)!important;
  transition: all 500ms;
  display: none!important;
}
